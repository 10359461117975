import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Material UI Components
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'

// Components
import Button from 'components/CustomButtons/Button'

// External
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

// Style
import { makeStyles, withStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceStyle.js'
import { useLocalStorageState } from 'hooks/useLocalStorage'
import moment from 'moment'
import { InvoiceProcess } from 'utils/Constants'

const useStyles = makeStyles(styles)

const ColorSwitch = withStyles({
  switchBase: {
    color: '#fafafa',
    '&$checked': {
      color: '#f15f22'
    },
    '&$checked + $track': {
      backgroundColor: '#f15f22'
    }
  },
  checked: {},
  track: {}
})(Switch)

const schema = yup.object().shape({
  name: yup
    .string()
    .min(1)
    .required(),
  description: yup
    .string()
    .min(1)
    .required(),
  enabled: yup.boolean(),
  confidenceInterval: yup
    .string()
    .min(1)
    .required(),
  required: yup.boolean(),
  autoReject: yup.boolean(),
  ignoreMappings: yup.boolean(),
  descMaxLength: yup.string().optional(),
  standardField: yup
    .string()
    .min(1)
    .required(),
  errorMessage: yup
    .string()
    .min(1)
    .required(),
  successMessage: yup
    .string()
    .min(1)
    .required(),
  typeField: yup
    .string()
    .min(1)
    .required(),
  level: yup
    .string()
    .min(1)
    .required(),
  process: yup
    .string()
    .min(1)
    .required()
})

export default function OcrForm(props) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [userInfo] = useLocalStorageState('userInfo', {})
  const { defaultValues, onSubmitForm } = props
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    mode: 'all',
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false
  })

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues)
    }
  }, [defaultValues, reset])

  function isError(field) {
    if (errors[field]) {
      return true
    }
    return false
  }

  function onSubmit(data) {
    data.updatedBy = {
      userId: userInfo.sub,
      email: userInfo.email,
      userName: userInfo.name,
      updatedDate: new Date().toISOString(),
      epochUpdatedDate: new Date().getTime()
    }

    setIsLoading(true)
    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Controller
            render={({ field }) => (
              <TextField fullWidth variant="outlined" className={classes.formField} label="Name*" error={isError('name')} {...field} />
            )}
            name="name"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Controller
            render={({ field }) => (
              <TextField
                InputProps={{
                  readOnly: true
                }}
                fullWidth
                variant="outlined"
                className={classes.formField}
                label="Standard Field*"
                error={isError('standardField')}
                {...field}
              />
            )}
            name="standardField"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl variant="outlined" className={classes.formField} error={isError('confidenceInterval')}>
            <InputLabel id="confidence-label">Confidence Interval*</InputLabel>
            <Controller
              render={({ field }) => (
                <Select labelId="confidence-label" label="Confidence Interval" {...field}>
                  <MenuItem disabled>Select Confidence Interval</MenuItem>
                  <MenuItem value="veryHigh">Very High</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="low">Low</MenuItem>
                </Select>
              )}
              name="confidenceInterval"
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl variant="outlined" className={classes.formField} error={isError('typeField')}>
            <InputLabel id="typeField-label">Type Field*</InputLabel>
            <Controller
              render={({ field }) => (
                <Select labelId="typeField-label" label="Type Field" {...field}>
                  <MenuItem disabled>Select Type Field</MenuItem>
                  <MenuItem value="string">String</MenuItem>
                  <MenuItem value="number">Number</MenuItem>
                  <MenuItem value="date">Date</MenuItem>
                  <MenuItem value="integer">Integer</MenuItem>
                  <MenuItem value="plantLocation">Company Address</MenuItem>
                </Select>
              )}
              name="typeField"
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl variant="outlined" className={classes.formField} error={isError('level')}>
            <InputLabel id="level-label">Level*</InputLabel>
            <Controller
              render={({ field }) => (
                <Select labelId="level-label" label="Level" {...field}>
                  <MenuItem disabled>Select Level</MenuItem>
                  <MenuItem value="header">Header</MenuItem>
                  <MenuItem value="lineItems">Line Items</MenuItem>
                  <MenuItem value="additionalCharges">Additional Charges</MenuItem>
                </Select>
              )}
              name="level"
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl variant="outlined" className={classes.formField} error={isError('process')}>
            <InputLabel id="process-label">Process*</InputLabel>
            <Controller
              render={({ field }) => (
                <Select labelId="process-label" label="Process" {...field} inputProps={{ readOnly: true }}>
                  <MenuItem disabled>Select Process</MenuItem>
                  {Object.keys(InvoiceProcess).map(key => (
                    <MenuItem id={key} value={key} key={key}>
                      {InvoiceProcess[key]}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="process"
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Controller
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                className={classes.formField}
                label="Description*"
                multiline
                rows="3"
                error={isError('description')}
                {...field}
              />
            )}
            name="description"
            control={control}
          />
        </Grid>
        {defaultValues.standardField === 'ITEM' ? (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              render={({ field }) => (
                <TextField
                  type="number"
                  fullWidth
                  variant="outlined"
                  className={classes.formField}
                  label="Max length"
                  error={isError('descMaxLength')}
                  {...field}
                />
              )}
              name="descMaxLength"
              control={control}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Controller
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                className={classes.formField}
                label="Error Message*"
                multiline
                rows="3"
                error={isError('errorMessage')}
                {...field}
              />
            )}
            name="errorMessage"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Controller
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                className={classes.formField}
                label="Success Message*"
                multiline
                rows="3"
                error={isError('successMessage')}
                {...field}
              />
            )}
            name="successMessage"
            control={control}
          />
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <br />
            <div>
              <p>
                Updated By: <span>{defaultValues.updatedBy?.userName || defaultValues.updatedBy?.email || ''}</span>
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div>
              <p>
                Date Updated:{' '}
                <span>
                  {defaultValues.updatedBy?.updatedDate ? moment(defaultValues.updatedBy?.updatedDate).format('MM/DD/YYYY, HH:mm') : ''}
                </span>
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControlLabel
                control={
                  <Controller
                    render={({ field }) => <ColorSwitch {...field} id={'required'} checked={Boolean(field.value)} />}
                    name={'required'}
                    control={control}
                  />
                }
                label={'Required'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControlLabel
                control={
                  <Controller
                    render={({ field }) => <ColorSwitch {...field} id={'autoReject'} checked={Boolean(field.value)} />}
                    name={'autoReject'}
                    control={control}
                  />
                }
                label={'Auto Reject'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControlLabel
                control={
                  <Controller
                    render={({ field }) => <ColorSwitch {...field} id={'ignoreMappings'} checked={Boolean(field.value)} />}
                    name={'ignoreMappings'}
                    control={control}
                  />
                }
                label={'Ignore Mappings'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControlLabel
                control={
                  <Controller
                    render={({ field }) => <ColorSwitch {...field} id={'enabled'} checked={Boolean(field.value)} />}
                    name={'enabled'}
                    control={control}
                  />
                }
                label={'Enabled'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
          <Button color="primary" type="submit" disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
