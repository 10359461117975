import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Material UI Components
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'

// Components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import { TablePaginationActions } from 'components/Invoice/TablePaginationActions'
import Snackbar from 'components/Snackbar/Snackbar'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import MaterialTable from 'material-table'

// Constants
import { InvoiceProcess, confidenceInterval, levelField, typeField } from 'utils/Constants'

// Icons
import AddAlert from '@material-ui/icons/AddAlert'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import TouchApp from '@material-ui/icons/TouchApp'

// Hooks
import { useGetAppList } from 'hooks/useApp'
import { useGetValidationRules } from 'hooks/useValidationRules'

// Style
import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceStyle.js'

const useStyles = makeStyles(styles)

const app = 'invoiceAI'

let breadcrumbViews = [
  {
    name: 'Validation Rules',
    url: '/admin/invoiceAi/validationRules',
    icon: AssignmentIcon
  }
]

export default function ValidationRules() {
  const classes = useStyles()
  let history = useHistory()
  const [appId, setAppId] = useState(null)
  const [processOcr, setProcessOcr] = useState(['*'])
  const [processMd, setProcessMd] = useState(['*'])
  const [createError, setCreateError] = useState({ isOpen: false, message: '' })
  const [paginationOcr, setPaginationOcr] = useState({
    page: 0,
    rowsPage: 5,
    lastPage: -1,
    lastEvaluatedKeys: {}
  })
  const [paginationMd, setPaginationMd] = useState({
    page: 0,
    rowsPage: 5,
    lastPage: -1,
    lastEvaluatedKeys: {}
  })
  const { data: appList, isLoading: isLoadingAppList } = useGetAppList(app)

  useEffect(() => {
    if (!appId && appList?.length > 0) {
      setAppId(appList[0].env)
    }
  }, [appList, appId])

  const theme = createTheme({
    palette: {
      secondary: {
        main: '#081c3e'
      }
    }
  })

  const ocrColumns = [
    {
      field: 'name',
      title: 'Name'
    },
    {
      field: 'standardField',
      title: 'Standard Field'
    },
    {
      field: 'typeField',
      title: 'Type',
      render: rowData => typeField[rowData.typeField]
    },
    {
      field: 'confidenceInterval',
      title: 'Confidence Interval',
      render: rowData => confidenceInterval[rowData.confidenceInterval]
    },
    {
      field: 'level',
      title: 'Level',
      render: rowData => levelField[rowData.level]
    },
    {
      field: 'process',
      title: 'Process',
      render: rowData => InvoiceProcess[rowData.process]
    },
    {
      field: 'autoReject',
      title: 'Auto Reject',
      align: 'center',
      render: rowData => (rowData.autoReject ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />)
    },
    {
      field: 'required',
      title: 'Required',
      align: 'center',
      render: rowData => (rowData.required ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />)
    },
    {
      field: 'enabled',
      title: 'Enabled',
      align: 'center',
      render: rowData => (rowData.enabled ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />)
    },
    {
      field: 'ignoreMappings',
      title: 'Ignore Mappings',
      align: 'center',
      render: rowData => (rowData.ignoreMappings ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />)
    }
  ]

  const masterDataColumns = [
    {
      field: 'name',
      title: 'Name'
    },
    {
      field: 'level',
      title: 'Level',
      render: rowData => levelField[rowData.level]
    },
    {
      field: 'process',
      title: 'Process',
      render: rowData => InvoiceProcess[rowData.process]
    },
    {
      field: 'autoReject',
      title: 'Auto Reject',
      align: 'center',
      render: rowData => (rowData.autoReject ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />)
    },
    {
      field: 'enabled',
      title: 'Enabled',
      align: 'center',
      render: rowData => (rowData.enabled ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />)
    }
  ]

  const ocrActions = [
    {
      icon: () => <EditIcon />,
      tooltip: 'Edit Rule',
      onClick: (event, rowData) => {
        const ruleId = encodeURIComponent(`${rowData.process}#${rowData.code}`)
        history.push(`/admin/invoiceAI/${appId}/ocrValidationRules/${ruleId}/edit`)
      }
    }
  ]

  const masterDataActions = [
    {
      icon: () => <EditIcon />,
      tooltip: 'Edit Rule',
      onClick: (event, rowData) => {
        const ruleId = encodeURIComponent(`${rowData.process}#${rowData.code}`)
        history.push(`/admin/invoiceAI/${appId}/masterDataValidationRules/${ruleId}/edit`)
      }
    }
  ]

  const {
    data: ocrRows,
    isLoading: isLoadingOcr,
    refetch: refetchOcr,
    isFetching: isFetchingOcr,
    isError: isErrorOcr,
    error: errorOcr
  } = useGetValidationRules(appId, 'ocr', paginationOcr, processOcr)

  useEffect(() => {
    if (!ocrRows) {
      return
    }

    if (!ocrRows?.lastEvaluatedKey) {
      setPaginationOcr(old => {
        return {
          ...old,
          lastPage: old.page
        }
      })
      return
    }

    setPaginationOcr(old => {
      return {
        ...old,
        lastEvaluatedKeys: {
          ...old.lastEvaluatedKeys,
          [old.page]: ocrRows?.lastEvaluatedKey
        }
      }
    })
  }, [ocrRows])

  const {
    data: masterDataRows,
    isLoading: isLoadingMasterData,
    refetch: refetchMasterData,
    isFetching: isFetchingMasterData,
    isError: isErrorMasterData,
    error: errorMasterData
  } = useGetValidationRules(appId, 'masterData', paginationMd, processMd)

  useEffect(() => {
    if (!masterDataRows) {
      return
    }

    if (!masterDataRows?.lastEvaluatedKey) {
      setPaginationMd(old => {
        return {
          ...old,
          lastPage: old.page
        }
      })
      return
    }

    setPaginationMd(old => {
      return {
        ...old,
        lastEvaluatedKeys: {
          ...old.lastEvaluatedKeys,
          [old.page]: masterDataRows?.lastEvaluatedKey
        }
      }
    })
  }, [masterDataRows])

  useEffect(() => {
    if (!isErrorOcr && !isErrorMasterData) {
      return
    }
    setCreateError({
      isOpen: true,
      message: 'Something went wrong, try again later'
    })
  }, [isErrorOcr, errorOcr, isErrorMasterData, errorMasterData])

  const handleChangePageOcr = (event, newPage) => {
    setPaginationOcr(old => {
      return {
        ...old,
        page: newPage
      }
    })
  }

  const handleChangePageMd = (event, newPage) => {
    setPaginationMd(old => {
      return {
        ...old,
        page: newPage
      }
    })
  }

  const handleChangeOcr = event => {
    setProcessOcr(event.target.value)
  }

  const handleChangeMd = event => {
    setProcessMd(event.target.value)
  }

  function ocrValidationRules() {
    return (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography>OCR Validation Rules</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            color="primary"
            size="small"
            className={classes.floatRight + ' ' + classes.colorPrimary}
            onClick={() => refetchOcr({ throwOnError: true })}
          >
            <RefreshRoundedIcon fontSize="small" />
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
            <InputLabel id="process-ocr-label">Process</InputLabel>
            <Select labelId="process-ocr-label" multiple value={processOcr} onChange={handleChangeOcr} input={<Input />}>
              <MenuItem disabled>Select the process</MenuItem>
              {Object.keys(InvoiceProcess).map(process => (
                <MenuItem key={process} value={process}>
                  {InvoiceProcess[process]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <SyncLoader showLoader={isFetchingOcr && !isLoadingOcr} />
          {isLoadingOcr || isLoadingAppList ? (
            <div className={classes.reloadSize}>
              <CircularProgress />
            </div>
          ) : (
            <ThemeProvider theme={theme}>
              <MaterialTable
                columns={ocrColumns}
                data={ocrRows?.validationsRules ?? []}
                onChangeRowsPerPage={pageSize => {
                  setPaginationOcr({
                    page: 0,
                    rowsPage: pageSize,
                    lastEvaluatedKeys: []
                  })
                }}
                actions={ocrActions}
                options={{
                  columnsButton: true,
                  actionsColumnIndex: -1,
                  sorting: true,
                  showTitle: false,
                  emptyRowsWhenPaging: false,
                  pageSize: paginationOcr.rowsPage,
                  pageSizeOptions: [5, 10, 20, 30, 40, 50]
                }}
                components={{
                  Pagination: props => {
                    return (
                      <TablePagination
                        {...props}
                        onChangePage={handleChangePageOcr}
                        ActionsComponent={subProps => <TablePaginationActions {...subProps} pagination={paginationOcr} />}
                      />
                    )
                  }
                }}
              />
            </ThemeProvider>
          )}
        </Grid>
      </Grid>
    )
  }

  function masterDataValidationRules() {
    return (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography>Master Data Validation Rules</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            color="primary"
            size="small"
            className={classes.floatRight + ' ' + classes.colorPrimary}
            onClick={() => refetchMasterData({ throwOnError: true })}
          >
            <RefreshRoundedIcon fontSize="small" />
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
            <InputLabel id="process-md-label">Process</InputLabel>
            <Select labelId="process-md-label" multiple value={processMd} onChange={handleChangeMd} input={<Input />}>
              <MenuItem disabled>Select the process</MenuItem>
              {Object.keys(InvoiceProcess).map(process => (
                <MenuItem key={process} value={process}>
                  {InvoiceProcess[process]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <SyncLoader showLoader={isFetchingMasterData && !isLoadingMasterData} />
          {isLoadingMasterData || isLoadingAppList ? (
            <div className={classes.reloadSize}>
              <CircularProgress />
            </div>
          ) : (
            <ThemeProvider theme={theme}>
              <MaterialTable
                columns={masterDataColumns}
                data={masterDataRows?.validationsRules ?? []}
                actions={masterDataActions}
                onChangeRowsPerPage={pageSize => {
                  setPaginationMd({
                    page: 0,
                    rowsPage: pageSize,
                    lastEvaluatedKeys: []
                  })
                }}
                options={{
                  columnsButton: true,
                  actionsColumnIndex: -1,
                  sorting: true,
                  showTitle: false,
                  emptyRowsWhenPaging: false,
                  pageSize: paginationMd.rowsPage,
                  pageSizeOptions: [5, 10, 20, 30, 40, 50]
                }}
                components={{
                  Pagination: props => {
                    return (
                      <TablePagination
                        {...props}
                        onChangePage={handleChangePageMd}
                        ActionsComponent={subProps => <TablePaginationActions {...subProps} pagination={paginationMd} />}
                      />
                    )
                  }
                }}
              />
            </ThemeProvider>
          )}
        </Grid>
      </Grid>
    )
  }

  function onAppChanged(id) {
    setAppId(id)
  }

  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={3}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Application List
              </Typography>
              {isLoadingAppList ? (
                <Card>
                  <Grid container justifyContent="center">
                    <div className={classes.circularProgress}>
                      <CircularProgress color="inherit" />
                    </div>
                  </Grid>
                </Card>
              ) : (
                <List>
                  {appList &&
                    appList.map((prop, key) => {
                      return (
                        <ListItem
                          className={classes.listItem}
                          button
                          selected={appId === prop.env}
                          key={key}
                          onClick={() => onAppChanged(prop.env)}
                        >
                          <ListItemAvatar>
                            {appId === prop.env ? (
                              <Avatar>
                                <TouchApp />
                              </Avatar>
                            ) : (
                              <Avatar className={classes.clearAvatar}></Avatar>
                            )}
                          </ListItemAvatar>
                          <ListItemText primary={prop.appName} secondary={`Type: ${prop.type}`} />
                        </ListItem>
                      )
                    })}
                </List>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Breadcrumbs views={breadcrumbViews} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  title={'Validation Rules'}
                  titleTypographyProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: '1.825em',
                      fontWeight: '300'
                    }
                  }}
                />
                <CardContent>
                  <Grid container justifyContent="center" spacing={5}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {ocrValidationRules()}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {masterDataValidationRules()}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        place="bl"
        color="danger"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() => setCreateError({ isOpen: false, message: '' })}
        close
      />
    </>
  )
}
