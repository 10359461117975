import { Badge, Box, CircularProgress, Fab, Tooltip } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { get } from 'lodash'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import AddIcon from '@material-ui/icons/Add'
import AddAlert from '@material-ui/icons/AddAlert'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import ErrorIcon from '@material-ui/icons/Error'
import MergeTypeIcon from '@material-ui/icons/MergeType'
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined'
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined'
import TabPanel from 'components/Invoice/TabPanel'
import { ValidationsModal } from 'components/Invoice/ValidationsModal'

import React, { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { maxDecimalAndSummation } from 'utils/functions'
// Form Validation
import Snackbar from 'components/Snackbar/Snackbar'

import { useFieldArray, useForm } from 'react-hook-form'

import ReactDataGrid from '@inovua/reactdatagrid-community'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import WarningIcon from '@material-ui/icons/Warning'
import {
  ColumnsOrderChargesTaxesStandard,
  ColumnsOrderLineItemsStandard,
  HeaderFieldsStandard
} from 'utils/Constants'

import '@inovua/reactdatagrid-community/index.css'
import CombineModal from '../../components/Invoice/CombineModal'
import { DeleteConfirmationModal } from '../../components/Invoice/ModalConfirmDelete'

import {
  useGetInvoiceComments,
  useUpdateInvoiceOcrDataBusinessRule,
  useUpdateStatusInvoice
} from 'hooks/useInvoices'
import { formatNumber } from 'utils/validator'

import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined'
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined'

import { ReactComponent as DoubleArrowIcon } from 'assets/img/invoices/caret-double-right.svg'
import InfoModal from 'components/Invoice/InfoModal'
import ModalComments from 'components/Invoice/ModalComments'
import { UpdateStatusDialog } from 'components/Invoice/UpdateStatusModal'
import {
  useMappingsInvoiceStandard,
  useUpdateInvoiceStandard,
  useValidateInvoiceStandard
} from 'hooks/useInvocieSandard'

import IgnoreModal from 'views/OcrInvoiceExceptions/IgnoreModal'
import ModalAccountType from './AccountModal'
import AdditionalDetails from './AdditionalDetails'
import AutoCompleteAsyncValuesHeader from './AutoCompleteAsyncValuesHeader'
import AddressSearch from './BuyerAddressSelect'
import AutoCompleteCountries from './CurrenvySelect'
import DatePickerField from './DatePickerField'
import { HeaderField } from './HeaderField'
import PoModal from './PoModal'
import PoModalDetails from './PoModalDetails'
import PoSearch from './PoSearch'
import RuleSelectModal from './RuleSelectModal'
import SelectCompanyCode from './SelectCompanyCode'
import SelectInvoiceType from './SelectInvoiceType'
import SelectProcess from './SelectProcess'
import SupplierAddressSelect from './SupplierAddressSelect'
import SupplierSearchId from './SupplierSearchId'
import SupplierSearchName from './supplierSearchName'
import SwitchField from './SwitchField'
import TaxLineModal from './TaxLineModal'
import { getAdditionalColumns, getColumns } from './useLines'
import {
  getStandardRule,
  getValidationRule,
  isTaxLineEnable,
  transform,
  transFormLines,
  updateHeader
} from './utils'

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  marginTexField: {
    flex: '0 0 50%',
    display: 'flex',
    margin: '2rem 0 0 0',
    justifyContent: 'left'
  },
  summaryFileds: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  otherFiledsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  summaryFiledsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  otherFiledsSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  otherFileds: {
    flex: '0 0 50%',
    display: 'flex',
    margin: '2rem 0 0 0',
    alignItems: 'center',
    justifyContent: 'center'
  },
  otherFiled: {},
  formControl: {
    minWidth: 100,
    maxWidth: 100,
    margin: ' 0 2rem 0 1rem'
  },
  notRecognizedLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formControlCountrie: {
    margin: theme.spacing(0.5),
    minWidth: 210,
    maxWidth: 300
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  },
  dropdown: {
    '-webkit-appearance': 'none',
    padding: '7px 40px 7px 12px',
    width: '100%',
    border: '1px solid #E8EAED',
    borderRadius: '5px',
    background: 'white',
    boxShadow: '0 1px 3px -2px #9098A9',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: '16px',
    transition: 'all 150ms ease',
    '&:focus': {
      outline: 'none',
      borderColor: '#0077FF',
      boxShadow: '0 0 0 2px rgba(#0077FF,.2)'
    },
    '&:hover': {
      stroke: '#0077FF'
    }
  },
  btnColorPrimary: {
    color: '#081c3e',
    border: '1px solid #081c3e',
    '&:hover': {
      backgroundColor: '#081c3e',
      color: 'white',
      border: '1px solid #081c3e'
    }
  },
  btnBgColorPrimary: {
    backgroundColor: '#081c3e',
    '&:hover': {
      backgroundColor: '#1E408A',
      border: '1px solid #1E408A'
    }
  },
  tabPanel: {
    maxHeight: '100%'
  },
  primaryColor: {
    color: '#081c3e !important'
  },
  disabledColor: {
    color: '#00000042 !important'
  },
  noHover: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    padding: '0px 16px 0px 16px !important'
  }
}))

const useStylesGridItemHeader = makeStyles(theme => ({
  root: {
    padding: '5px',
    marginBottom: '1rem'
  }
}))

const useStylesButtons = makeStyles(theme => ({
  startICon: {
    margin: 0
  }
}))

const useStylesBadges = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    },
    display: 'flex',
    justifyContent: 'flex-end'
  },
  warningBadge: {
    backgroundColor: '#f3bc07',
    color: 'white'
  },
  errorBadge: {
    backgroundColor: 'red',
    color: 'white'
  },
  badge: {
    backgroundColor: '#081c3e',
    color: 'white'
  }
}))

function sortElements(a, b) {
  return a.pos - b.pos
}

const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
  autoHide: true,
  alwaysShowTrack: true,
  scrollThumbStyle: {
    background: '#1E408A'
  }
})

function countErrorsAndWarnings({ formErrors, header, lineItems, headerFieldsForm }) {
  let errorInfo = []
  let warningInfo = []

  // eslint-disable-next-line
  for (const [index, value] of headerFieldsForm.entries()) {
    const existFieldError = header.errors.some(x => x.field === value.field)

    if (!formErrors || !formErrors[index] || existFieldError) {
      continue
    }

    // eslint-disable-next-line
    for (const error of Object.values(formErrors[index])) {
      errorInfo.push({
        section: 'Header',
        messages: [{ message: error.message }]
      })
    }
  }

  // eslint-disable-next-line
  for (const validation of header.errors) {
    // eslint-disable-next-line
    for (const detail of validation.details) {
      if (detail.criticalityType === 'critical') {
        errorInfo.push({
          section: 'Header',
          messages: [{ message: detail.message }]
        })
      } else {
        warningInfo.push({
          section: 'Header',
          messages: [{ message: detail.message }]
        })
      }
    }
  }

  const numberLine = lineItems.lines.length
  // eslint-disable-next-line
  for (const line of lineItems.errors) {
    const lineInformation = lineItems.lines.find(x => x.id === line.id)
    if (!lineInformation) {
      continue
    }
    // eslint-disable-next-line
    for (const lineError of line.errors) {
      // eslint-disable-next-line
      for (const detail of lineError.details) {
        if (detail.criticalityType === 'critical') {
          errorInfo.push({
            section: lineInformation.lineNumber
              ? `Line Item #${lineInformation.lineNumber}`
              : 'Line Item',
            messages: [{ message: detail.message }]
          })
        } else {
          warningInfo.push({
            section: `Line Item #${lineInformation.lineNumber || numberLine}`,
            messages: [{ message: detail.message }]
          })
        }
      }
    }
  }

  return {
    validationCount: {
      errorCount: errorInfo.length,
      warningCount: warningInfo.length
    },
    validationInfo: {
      errorInfo,
      warningInfo
    }
  }
}

export const ExceptionFields = React.forwardRef((props, ref) => {
  const {
    standardInvoice,
    appId,
    invoiceId,
    onChangeTab,
    tab,
    appConfig,
    invoiceData,
    onChangeInvoice,
    invoicesExceptions,
    requiredFields,
    readOnly,
    lineItems,
    additionalCharges,
    lineItemsActions,
    additionalChargesActions,
    headerData,
    headerFieldsActions,
    taxes,
    taxesActions,
    connectivityConfig,
    processList
  } = props

  const {
    setSelectedLines,
    editLine,
    addLine,
    deleteLines,
    cloneLine,
    mergeLines,
    consolidateLines,
    splitLine,
    updatePagination,
    updatePoLines,
    editLines
  } = lineItemsActions

  const {
    setSelectedLines: setSelectedAdditionalCharges,
    editLine: editLineAdditionalCharges,
    addLine: addLineAdditionalCharges,
    deleteLines: deleteLinesAdditionalCharges,
    cloneLine: cloneLineAdditionalCharges,
    mergeLines: mergeLinesAdditionalCharges,
    consolidateLines: consolidateAdditionalCharges,
    updatePagination: updatePaginationAdditionalCharges
  } = additionalChargesActions

  const {
    setSelectedLines: setSelectedTaxes,
    editLine: editLineTaxes,
    addLine: addLineTaxes,
    deleteLines: deleteLinesTaxes,
    cloneLine: cloneLineTaxes,
    updatePagination: updatePaginationTaxes,
    editLines: editLinesTaxes
  } = taxesActions

  const selectedLine =
    Object.keys(lineItems.selectedLine.cell ?? {}).length === 1
      ? Object.keys(lineItems.selectedLine.cell ?? {})[0].split(',')
      : []

  const accountSegmentsEnabled = connectivityConfig?.data?.enabled ?? false

  const isAccountValidationEnabled =
    connectivityConfig?.data?.isExistingAccountValidationEnabled ?? false
  const isCustomerMappingsEnabled = appConfig?.data.params?.ocr?.isCustomerDefinedMappingsEnabled

  const history = useHistory()
  const classesContainer = useStylesGridItemHeader()
  const classesButtons = useStylesButtons()
  const classesBadges = useStylesBadges()
  const gridStyle = { minHeight: lineItems.length >= 20 ? 725 : 500 }
  const gridChargesStyle = {
    minHeight: 300
  }
  const classes = useStyles()

  const theme = useTheme()
  const queryClient = useQueryClient()

  const [createError, setCreateError] = useState({
    message: '',
    isOpen: false,
    color: 'danger'
  })

  const [, setGridRef] = useState(null)
  const [, setGridAdditionalRef] = useState(null)
  const [, setGridTaxesRef] = useState(null)

  const [submitModal, setSubmitModal] = useState({
    isOpen: false,
    data: null,
    messageBody: null
  })
  const [poModal, setPoModal] = useState({
    update: false,
    details: false
  })

  const [submitSuccess, setSubmitSuccess] = useState({
    message: '',
    isOpen: false
  })

  const [isPoWarning, setIsPoWarning] = useState(true)

  const [poInformation, setPoInformation] = useState(null)

  const [showModal, setShowModal] = useState({
    isOpen: false,
    info: [],
    type: ''
  })
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, rows: null })

  const commentsInformation = useGetInvoiceComments({ appId, invoiceId })

  const [simpleModal, setSimpleModal] = useState({
    isOpenAddressModal: false,
    isOpenIgnoreModal: false,
    isOpenRejectModal: false,
    isOpenCombineModal: false,
    isOpenResetModal: false,
    isOpenCommentsModal: false,
    isOpenAccountTypeModal: false,
    isOpenSaveModal: false,
    isOpenSubmitModal: false,
    isOpenAppListModal: false,
    isOpenAppUpdateModal: false,
    isOpenRulesModal: false,
    taxLine: false
  })

  const {
    control,
    setValue: setValueForm,
    reset,
    getValues,
    formState: { errors },
    trigger
  } = useForm({
    mode: 'all'
  })

  const { fields: headerFields } = useFieldArray({
    control,
    name: 'headerFields'
  })
  const [lineType, setLineType] = useState('')
  //const [selectedIndex, setSelectedIndex] = useState(0)
  const [errorsForm, setErrorForm] = useState(null)

  const { validationCount, validationInfo } = countErrorsAndWarnings({
    formErrors: tab === 0 ? errors?.headerFields : errorsForm.headerFields,
    header: headerData,
    lineItems,
    headerFieldsForm: headerFields
  })
  const applyBusinessRule = useUpdateInvoiceOcrDataBusinessRule()
  const { mutate: updateData, isLoading: isLoadingUpdate } = useUpdateInvoiceStandard(invoiceId)

  const validationInvoice = useValidateInvoiceStandard(invoiceId)
  const updateStatusInvoice = useUpdateStatusInvoice(appId, invoiceId)
  const applyPOMappings = useMappingsInvoiceStandard()

  useEffect(() => {
    const headerFields = requiredFields.filter(x => x.level === 'header' && x.isVisible)
    const fields = []
    // eslint-disable-next-line
    for (const field of headerFields) {
      let standardField = field.standardField
      if (standardField.includes('CUSTOM_')) {
        standardField = `custom.${standardField}`
      }
      const value = get(standardInvoice.invoice.invoice.header, standardField)

      if (
        field.standardField === HeaderFieldsStandard['supplier.address.fullAddress'].type ||
        field.standardField === HeaderFieldsStandard['supplier.remitToAddress.fullAddress'].type
      ) {
        const addressId =
          field.standardField === HeaderFieldsStandard['supplier.remitToAddress.fullAddress'].type
            ? standardInvoice?.invoice.invoice.header.supplier?.remitAddress?.id
            : standardInvoice?.invoice.invoice.header.supplier?.address?.id
        fields.push({
          field: field.standardField,
          value: addressId ?? '',
          address: value ?? ''
        })
        continue
      }

      if (
        field.standardField ===
          HeaderFieldsStandard['companyProfile.billToAddress.fullAddress'].type ||
        field.standardField ===
          HeaderFieldsStandard['companyProfile.shipToAddress.fullAddress'].type
      ) {
        const addressId =
          field.standardField ===
          HeaderFieldsStandard['companyProfile.shipToAddress.fullAddress'].type
            ? standardInvoice?.invoice.invoice.header.companyProfile?.shipAddress?.id
            : standardInvoice?.invoice.invoice.header.companyProfile?.billingAddress?.id
        fields.push({
          field: field.standardField,
          value: addressId ?? '',
          address: value ?? ''
        })
        continue
      }

      if (field.standardField === HeaderFieldsStandard.charges.type) {
        const otherCharges = standardInvoice.invoice.invoice.detail.additionalCharges.map(line =>
          Number(line.total ?? 0)
        )
        const otherChargesSum = maxDecimalAndSummation(otherCharges).summation
        fields.push({
          field: field.standardField,
          value: otherChargesSum
        })
        continue
      }

      if (
        field.standardField === HeaderFieldsStandard.duplicateInvoiceFlag.type &&
        !appConfig?.data.params?.invoices?.duplicateInvoiceCheck.enabled
      ) {
        continue
      }
      fields.push({
        field: field.standardField,
        value: value
      })
    }

    reset({ headerFields: fields })
  }, [reset, requiredFields, standardInvoice, appConfig])

  useEffect(() => {
    if (!headerFields) {
      return
    }
    trigger()
  }, [headerFields, trigger])

  const onChangeChargeType = (lineId, newValue) => {
    const data = additionalCharges.lines.find(line => line.id === lineId)

    if (!data) {
      return
    }

    const columnId = ColumnsOrderChargesTaxesStandard.type.type
    editLineAdditionalCharges({
      columnId,
      data,
      value: newValue,
      requiredFields,
      setCreateError
    })
  }

  const onChangeTaxType = (lineId, newValue) => {
    const data = taxes.lines.find(line => line.id === lineId)

    if (!data) {
      return
    }

    const columnId = ColumnsOrderChargesTaxesStandard.type.type
    editLineTaxes({
      columnId,
      data,
      value: newValue,
      requiredFields,
      setCreateError
    })
  }

  const onChangePoLineItem = (lineId, newValue) => {
    const lineItemData = lineItems.lines.find(line => line.id === lineId)
    const dataValues = getValues()

    const po = dataValues.headerFields.find(
      summaryField => summaryField.field === HeaderFieldsStandard.poNumber.type
    )

    if (!po?.value || !lineItemData) {
      return
    }
    const columnId = ColumnsOrderLineItemsStandard.poLineNumber.type
    editLine({
      columnId,
      data: lineItemData,
      value: newValue,
      requiredFields,
      setCreateError
    })
    const lineItemDataUpdated = lineItems.lines.find(line => line.id === lineId)
    const body = {
      appId: invoiceData.appId,
      process: invoiceData.process,
      lineItems: [lineItemDataUpdated],
      lineItemId: lineItemData.id
    }
    applyPOMappings.mutate(
      {
        url: `/master-data/internal/purchase-orders/${po.value}/mappings/invoices-ai/${invoiceId}/poLineMappings`,
        body
      },
      {
        onSuccess: (response, variables, context) => {
          const { data } = response

          const lineItem = {}
          lineItem[lineItemData.id] = data.newLineItem

          lineItemsActions.updateLines({
            data: {
              lines: lineItem,
              accounting: data.accounting
            },
            requiredFields,
            columns: columnsData.columns.map(x => x.name ?? '')
          })
        },
        onError: error => {
          setCreateError({
            isOpen: true,
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            color: 'danger'
          })
        }
      }
    )
  }

  const columnsData = getColumns({
    lines: lineItems,
    poInformation: poInformation?.lineItemsPo,
    classes,
    setCreateError,
    requiredFields,
    onChangePoLineItem,
    showAddressesModal: () => setSimpleModal(old => ({ ...old, isOpenAddressModal: true })),
    showAccountSegments: () => setSimpleModal(old => ({ ...old, isOpenAccountTypeModal: true })),
    accountSegmentsEnabled: accountSegmentsEnabled,
    showTaxes: () => setSimpleModal(old => ({ ...old, taxLine: true }))
  })

  const columnsCharges = getAdditionalColumns({
    lines: additionalCharges,
    classes,
    setCreateError,
    requiredFields,
    appConfig,
    processValue: invoiceData.process,
    onChangeType: onChangeChargeType,
    type: 'additionalCharge'
  })

  const columnsTaxes = getAdditionalColumns({
    lines: taxes,
    classes,
    setCreateError,
    requiredFields,
    appConfig,
    processValue: invoiceData.process,
    onChangeType: onChangeTaxType,
    type: 'taxLine'
  })

  const updateSkip = useCallback(
    type => {
      return () => updatePagination({ type })
    },
    [updatePagination]
  )

  const updateSkipAdditionalCharges = useCallback(
    type => {
      return () => updatePaginationAdditionalCharges({ type })
    },
    [updatePaginationAdditionalCharges]
  )

  const updateSkipTaxes = useCallback(
    type => {
      return () => updatePaginationTaxes({ type })
    },
    [updatePaginationTaxes]
  )

  const onCellClick = useCallback(
    (event, cellProps) => {
      const { name, data } = cellProps
      const cellSelected = { [`${data.id},${name}`]: true }
      setSelectedLines({ cellSelected })
    },
    [setSelectedLines]
  )

  const onCellClickAdditionalCharges = useCallback(
    (event, cellProps) => {
      const { name, data } = cellProps
      const cellSelected = { [`${data.id},${name}`]: true }
      setSelectedAdditionalCharges({ cellSelected })
    },
    [setSelectedAdditionalCharges]
  )

  const onCellClickTaxes = useCallback(
    (event, cellProps) => {
      const { name, data } = cellProps
      const cellSelected = { [`${data.id},${name}`]: true }
      setSelectedTaxes({ cellSelected })
    },
    [setSelectedTaxes]
  )

  const onEditCompleteAdditionalCharges = useCallback(
    ({ value, columnId, data }) => {
      editLineAdditionalCharges({
        columnId,
        data,
        value,
        requiredFields,
        setCreateError
      })
    },
    [editLineAdditionalCharges, requiredFields]
  )

  const onEditCompleteTaxes = useCallback(
    ({ value, columnId, data }) => {
      editLineTaxes({
        columnId,
        data,
        value,
        requiredFields,
        setCreateError
      })
    },
    [editLineTaxes, requiredFields]
  )

  const onEditComplete = useCallback(
    ({ value, columnId, data }) => {
      editLine({
        columnId,
        data,
        value,
        requiredFields,
        setCreateError
      })
    },
    [editLine, requiredFields]
  )

  const handleChange = (event, newValue) => {
    onChangeTab(old => {
      if (old === 0 && old !== newValue) {
        setErrorForm(JSON.parse(JSON.stringify(errors)))
      }
      return newValue
    })
  }

  const handleChangeIndex = index => {
    onChangeTab(old => {
      if (old === 0 && old !== index) {
        setErrorForm(JSON.parse(JSON.stringify(errors)))
      }
      return index
    })
    trigger()
  }

  const onSelectionChange = useCallback(
    ({ selected, unselected }) => {
      setSelectedLines({ selected: selected, unselected: unselected })
    },
    [setSelectedLines]
  )

  const onSelectionChangeAdditionalCharges = useCallback(
    ({ selected, unselected }) => {
      setSelectedAdditionalCharges({ selected: selected, unselected: unselected })
    },
    [setSelectedAdditionalCharges]
  )

  const onSelectionChangeTaxes = useCallback(
    ({ selected, unselected }) => {
      setSelectedTaxes({ selected: selected, unselected: unselected })
    },
    [setSelectedTaxes]
  )

  const cloneLineItem = rowProps => {
    cloneLine({
      columns: columnsData.columns,
      data: rowProps.data
    })
  }

  const splitLineItem = rowProps => {
    splitLine({ columns: columnsData.columns, data: rowProps.data })
  }

  const cloneLineCharge = rowProps => {
    cloneLineAdditionalCharges({
      columns: columnsCharges.columns,
      data: rowProps.data
    })
  }

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    menuProps.autoDismiss = true
    menuProps.items = [
      {
        label: 'Clone Line',
        onClick: () => cloneLineItem(rowProps)
      },
      {
        label: 'Split Line',
        onClick: () => splitLineItem(rowProps)
      }
    ]
  }

  const renderRowContextMenuAdditionalCharges = (menuProps, { rowProps, cellProps }) => {
    menuProps.autoDismiss = true
    menuProps.items = [
      {
        label: 'Clone Line',
        onClick: () => cloneLineCharge(rowProps)
      }
    ]
  }

  const renderRowContextMenuTaxes = (menuProps, { rowProps, cellProps }) => {
    menuProps.autoDismiss = true
    menuProps.items = [
      {
        label: 'Clone Line',
        onClick: () => cloneLineTaxes(rowProps)
      }
    ]
  }

  const onClose = () => {
    setDeleteModal({ isOpen: false, index: null })
  }

  const onAddNewRow = () => {
    addLine({ columns: columnsData.columns, requiredFields })
  }

  const onAddNewRowAdditional = () => {
    addLineAdditionalCharges({ columns: columnsCharges.columns, requiredFields })
  }

  async function changeInvoiceStatus(status, message) {
    const body = { status: status, message: message }
    updateStatusInvoice.mutate(body, {
      onSettled: () => {
        queryClient.invalidateQueries(['invoices', invoiceId])
      },
      onSuccess: () => {
        history.push(`/admin/invoiceAI/${invoiceId}/details`)
      },
      onError: error => {
        setCreateError({
          isOpen: true,
          message: error.response?.data?.message ?? 'Something went wrong, try again later',
          color: 'danger'
        })
      }
    })
  }

  const onAddNewRowTaxes = () => {
    addLineTaxes({ columns: columnsTaxes.columns, requiredFields })
  }

  function handleDeleteBatchLineItems(type) {
    setDeleteModal({ isOpen: true })
    setLineType(type)
  }

  function handleMergeRows() {
    setSimpleModal(old => ({ ...old, isOpenCombineModal: true }))
  }

  function onSuccessMergeRows(isMerge) {
    if (tab === 1) {
      if (isMerge) {
        mergeLinesAdditionalCharges()
      } else {
        consolidateAdditionalCharges()
      }
    } else {
      if (isMerge) {
        mergeLines()
      } else {
        consolidateLines()
      }
    }
  }

  function handleDelete() {
    if (lineType === 'charges') {
      deleteLinesAdditionalCharges()
    } else if (lineType === 'taxes') {
      deleteLinesTaxes()
    } else {
      deleteLines()
    }
  }

  const changePo = useCallback(
    data => {
      setPoInformation(data)
      updatePoLines({
        data
      })
    },
    [updatePoLines]
  )

  function applyPoMappings(flags) {
    const data = getValues()

    const po = data.headerFields.find(
      summaryField => summaryField.field === HeaderFieldsStandard.poNumber.type
    )

    if (!po?.value) {
      return
    }

    const body = {
      flags,
      lineItems: lineItems.lines,

      appId: invoiceData.appId,
      process: invoiceData.process
    }

    applyPOMappings.mutate(
      {
        url: `/master-data/internal/purchase-orders/${po.value}/mappings/invoices-ai/${invoiceId}`,
        body
      },
      {
        onSuccess: (response, variables, context) => {
          const { data } = response

          const { body } = variables
          const { flags } = body

          const {
            buyerBillAddress,
            buyerShipAddress,
            companyCode,
            currency,
            supplierShipAddress,
            vendorIdVendorName
          } = flags

          const fieldsToUpdate = []
          const errorsToDelete = []
          if (buyerBillAddress) {
            fieldsToUpdate.push({
              field: HeaderFieldsStandard['companyProfile.billToAddress.id'].type,
              value: data.companyProfileData.billingAddress?.id
            })

            fieldsToUpdate.push({
              field: HeaderFieldsStandard['companyProfile.billToAddress.fullAddress'].type,
              value: data.companyProfileData.billingAddress?.name
            })
            errorsToDelete.push(HeaderFieldsStandard['companyProfile.billToAddress.id'].type)
            errorsToDelete.push(
              HeaderFieldsStandard['companyProfile.billToAddress.fullAddress'].type
            )
          }

          if (buyerShipAddress) {
            fieldsToUpdate.push({
              field: HeaderFieldsStandard['companyProfile.shipToAddress.id'].type,
              value: data.companyProfileData.shipAddress?.id
            })
            fieldsToUpdate.push({
              field: HeaderFieldsStandard['companyProfile.shipToAddress.fullAddress'].type,
              value: data.companyProfileData.shipAddress?.name
            })
            errorsToDelete.push(HeaderFieldsStandard['companyProfile.shipToAddress.id'].type)
            errorsToDelete.push(
              HeaderFieldsStandard['companyProfile.shipToAddress.fullAddress'].type
            )
          }

          if (companyCode) {
            fieldsToUpdate.push({
              field: HeaderFieldsStandard['companyProfile.id'].type,
              value: data.companyProfileData.id
            })
            errorsToDelete.push(HeaderFieldsStandard['companyProfile.id'].type)
          }

          if (currency) {
            fieldsToUpdate.push({
              field: HeaderFieldsStandard['currency.code'].type,
              value: data.currency
            })
            errorsToDelete.push(HeaderFieldsStandard['currency.code'].type)
          }

          if (supplierShipAddress) {
            fieldsToUpdate.push({
              field: HeaderFieldsStandard['supplier.address.id'].type,
              value: data.supplierData.address?.id
            })
            fieldsToUpdate.push({
              field: HeaderFieldsStandard['supplier.address.fullAddress'].type,
              value: data.supplierData.address?.name
            })
            errorsToDelete.push(HeaderFieldsStandard['supplier.address.id'].type)
            errorsToDelete.push(HeaderFieldsStandard['supplier.address.fullAddress'].type)
          }

          if (vendorIdVendorName) {
            fieldsToUpdate.push({
              field: HeaderFieldsStandard['supplier.id'].type,
              value: data.supplierData.id
            })
            fieldsToUpdate.push({
              field: HeaderFieldsStandard['supplier.name'].type,
              value: data.supplierData.name
            })
            errorsToDelete.push(HeaderFieldsStandard['supplier.id'].type)
            errorsToDelete.push(HeaderFieldsStandard['supplier.name'].type)
          }

          const dataForm = getValues()

          // eslint-disable-next-line
          for (const fieldToUpdate of fieldsToUpdate) {
            const index = dataForm.headerFields.findIndex(
              summaryField => summaryField.field === fieldToUpdate.field
            )

            if (index < 0) {
              continue
            }
            let fieldPosition = `headerFields.${index}.value`

            if (
              fieldToUpdate.field ===
                HeaderFieldsStandard['companyProfile.billToAddress.fullAddress'].type ||
              fieldToUpdate.field ===
                HeaderFieldsStandard['companyProfile.shipToAddress.fullAddress'].type ||
              fieldToUpdate.field === HeaderFieldsStandard['supplier.address.fullAddress'].type
            ) {
              fieldPosition = `headerFields.${index}.address`
            }
            setValueForm(fieldPosition, fieldToUpdate.value ?? '', {
              shouldValidate: true,
              shouldDirty: true
            })
          }

          lineItemsActions.updateLines({
            data: {
              lines: data.lineItems,
              accounting: data.accounting
            },
            requiredFields,
            columns: columnsData.columns.map(x => x.name ?? '')
          })
          headerFieldsActions.deleteErrorsHeader({
            fields: errorsToDelete
          })
        },
        onError: error => {
          setCreateError({
            isOpen: true,
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            color: 'danger'
          })
        }
      }
    )
  }

  function updateTotals() {
    const lineItemsUpdatedAmount = lineItems.lines.map(item => {
      if (item.unitPrice && item.quantity) {
        const total = Number(item.unitPrice) * Number(item.quantity)
        item.total = total
        return item
      }

      return item
    })

    const summaryFields = getValues('headerFields')

    const index = summaryFields.findIndex(
      summaryField => summaryField.field === HeaderFieldsStandard.tax.type
    )

    const totalFieldIndex = summaryFields.findIndex(
      summaryField => summaryField.field === HeaderFieldsStandard.total.type
    )
    const subtotalFieldIndex = summaryFields.findIndex(
      summaryField => summaryField.field === HeaderFieldsStandard.subtotal.type
    )
    const discountFieldIndex = summaryFields.findIndex(
      summaryField => summaryField.field === HeaderFieldsStandard.discount.type
    )

    const otherChargesFieldIndex = summaryFields.findIndex(
      summaryField => summaryField.field === HeaderFieldsStandard.charges.type
    )

    if (
      index < 0 ||
      totalFieldIndex < 0 ||
      subtotalFieldIndex < 0 ||
      discountFieldIndex < 0 ||
      otherChargesFieldIndex < 0
    ) {
      return
    }
    let subtotalSum = 0
    let taxSum = 0
    let otherChargesSum = 0

    const totalField = summaryFields[totalFieldIndex]
    const discountField = summaryFields[discountFieldIndex]

    let total = Number(totalField?.value ?? 0)

    const subtotal = lineItemsUpdatedAmount.map(lineItem => Number(lineItem.total ?? 0))
    const taxValues = taxes.lines.map(line => Number(formatNumber(`${line.amount}`) ?? 0))
    const otherCharges = additionalCharges.lines.map(line => Number(line.total ?? 0))

    otherChargesSum = maxDecimalAndSummation(otherCharges).summation
    subtotalSum = maxDecimalAndSummation(subtotal).summation

    const taxableAmount = subtotalSum + otherChargesSum
    let taxesUpdated = []

    const linesToUpdate = lineItemsUpdatedAmount.map(x => {
      return {
        id: x.id,
        fields: [{ column: 'total', value: x.total }]
      }
    })

    if (isTaxLineEnable()) {
      const taxChargesMap = new Map(Object.entries(lineItems.taxAndCharges ?? {}))

      // eslint-disable-next-line
      for (const line of linesToUpdate) {
        const oldTaxesCharges = taxChargesMap.get(line.id)
        // eslint-disable-next-line
        for (const field of line.fields) {
          if (oldTaxesCharges) {
            const newTaxesLine = { ...oldTaxesCharges }
            const lineAmount = Number(field.value)
            const isInvalidValue = isNaN(lineAmount) || lineAmount === 0
            newTaxesLine.taxes = newTaxesLine.taxes?.map(x => ({
              ...x,
              amount: isInvalidValue
                ? ''
                : `${((Number(x.rate || 0) / 100) * Number(field.value)).toFixed(2)}`
            }))

            taxSum += (newTaxesLine?.taxes || []).reduce(
              (total, item) => total + Number(item.amount),
              0
            )
          }
        }
      }
    } else {
      taxesUpdated = taxes.lines.map(line => {
        if (line.rate && Number(line.rate) === 0) {
          const taxAmount = Number(line.amount ?? 0)
          const taxRate = ((taxAmount / taxableAmount) * 100).toFixed(2)

          line.rate = Number(formatNumber(`${taxRate}`))
        }
        return line
      })
      taxSum = maxDecimalAndSummation(taxValues).summation
    }

    const values = [subtotalSum, taxSum, otherChargesSum, -Number(discountField?.value ?? 0)]

    const { summation: totalSum } = maxDecimalAndSummation(values)
    total = totalSum

    const taxesToUpdate = taxesUpdated.map(x => {
      return {
        id: x.id,
        fields: [{ column: 'rate', value: x.rate }]
      }
    })

    editLines({
      lines: linesToUpdate
    })

    editLinesTaxes({
      lines: taxesToUpdate
    })

    setValueForm(`headerFields.${index}.value`, taxSum, {
      shouldValidate: true,
      shouldDirty: true
    })
    setValueForm(`headerFields.${otherChargesFieldIndex}.value`, otherChargesSum, {
      shouldValidate: true,
      shouldDirty: true
    })
    setValueForm(`headerFields.${totalFieldIndex}.value`, total, {
      shouldValidate: true,
      shouldDirty: true
    })

    setValueForm(`headerFields.${subtotalFieldIndex}.value`, subtotalSum, {
      shouldValidate: true,
      shouldDirty: true
    })
    setSubmitSuccess({
      message: 'Totals have been calculated',
      isOpen: true
    })

    const fieldToChangeValidation = [
      HeaderFieldsStandard.tax.type,
      HeaderFieldsStandard.total.type,
      HeaderFieldsStandard.subtotal.type,
      HeaderFieldsStandard.discount.type
    ]

    headerFieldsActions.deleteErrorsHeader({
      fields: fieldToChangeValidation
    })

    handleChangeIndex(0)
  }

  const onSave = () => {
    const data = getValues()

    const headerFields = transform(data.headerFields)
    const body = {
      headerFields: {
        fields: headerFields,
        errors: headerData.errors
      },
      lineItems: {
        lines: transFormLines(lineItems.lines),
        errors: lineItems.errors.filter(x => x.errors.length > 0),
        accounting: lineItems.lineItemsAccountSegments
      },
      lineCharges: {
        lines: additionalCharges.lines,
        errors: additionalCharges.errors.filter(x => x.errors.length > 0)
      },
      lineTaxes: {
        lines: taxes.lines,
        errors: taxes.errors.filter(x => x.errors.length > 0)
      },
      linesAdditionalData: lineItems.taxAndCharges
    }

    updateData(body, {
      onSettled: (response, err, data) => {
        queryClient.invalidateQueries(['invoices', invoiceId])
        queryClient.invalidateQueries(['invoices', invoiceId, 'standard'])
      },
      onSuccess: (_, data) => {
        setSubmitSuccess({ message: 'Saved', isOpen: true })
      },
      onError: error => {
        setCreateError({
          message: error.response?.data?.message ?? 'Something went wrong, try again later',
          isOpen: true,
          color: 'danger'
        })
      }
    })
  }

  const onSubmit = () => {
    const rule = getStandardRule({
      requiredFields,
      level: 'lineItem',
      standardField: ColumnsOrderLineItemsStandard.accounting.type
    })
    const requiredField = getValidationRule(rule, 'required')

    if (accountSegmentsEnabled && requiredField) {
      lineItemsActions.validateAccountSegments({ requiredFields })
      let isValidSegments = true
      // eslint-disable-next-line
      for (const row of Object.values(lineItems.lines)) {
        if (!row.id) {
          continue
        }

        let isValidLineAccount = true
        const accountSegment = lineItems.lineItemsAccountSegments?.[row.id]

        if (!accountSegment || accountSegment.accountSegments.length === 0) {
          isValidSegments = false
          continue
        }
        isValidLineAccount = accountSegment.accountSegments.every(x => x.accountId)
        if (!isValidLineAccount) {
          isValidSegments = false
          continue
        }
      }

      if (!isValidSegments) {
        setSubmitModal({
          isOpen: true,
          data: null,
          messageBody: 'One or more lines are missing accounting information.',
          successBtn: false
        })
        return
      }
    }

    const data = getValues()

    const headerFields = transform(data.headerFields, { isSubmit: true })
    const body = {
      headerFields: {
        fields: headerFields,
        errors: headerData.errors
      },
      lineItems: {
        lines: transFormLines(lineItems.lines),
        errors: lineItems.errors.filter(x => x.errors.length > 0),
        accounting: lineItems.lineItemsAccountSegments
      },
      lineCharges: {
        lines: additionalCharges.lines,
        errors: additionalCharges.errors.filter(x => x.errors.length > 0)
      },
      lineTaxes: {
        lines: taxes.lines,
        errors: taxes.errors.filter(x => x.errors.length > 0)
      },
      linesAdditionalData: lineItems.taxAndCharges
    }

    let messageBody = <p>Are you sure you want to submit this invoice?</p>

    setSubmitModal({ isOpen: true, data: body, messageBody })
  }

  const onSubmitModal = () => {
    validationInvoice.mutate(submitModal.data, {
      onSettled: () => {
        queryClient.resetQueries(['invoices', invoiceId], {
          exact: true
        })
        queryClient.resetQueries(['invoices', invoiceId, 'standard'], {
          exact: true
        })
      },
      onSuccess: () => {
        history.push(`/admin/invoiceAI/${invoiceId}/details`)
      }
    })
  }

  function handleOnCompleteAccountSegments(data) {
    if (!selectedLine || selectedLine.length === 0 || !data) {
      return
    }

    lineItemsActions.updateAccount({
      id: selectedLine[0],
      account: data
    })
  }

  function onSuccessTaxesLine(taxes) {
    lineItemsActions.updateTaxesLine(taxes)
  }

  function updateInvoiceOcrDataCustomRules(rules = []) {
    const data = getValues()
    const headerFields = transform(data.headerFields)
    const lines = transFormLines(lineItems.lines)
    const body = {
      headerFields: headerFields,
      lineItems: lines,
      lineCharges: additionalCharges.lines,
      lineTaxes: taxes.lines,
      rules: rules,
      appId,
      linesAdditionalData: lineItems.taxAndCharges
    }
    // rule engine
    applyBusinessRule.mutate(
      { url: `/management/businessRules/invoices-ai/${invoiceData.invoiceId}`, body },
      {
        onSuccess: response => {
          const brSetField = response.find(x => x.type === 'setField')
          if (brSetField) {
            const deltaRes = brSetField.payload
            updateHeader({
              delta: deltaRes,
              setValue: setValueForm,
              trigger,
              getValues,
              requiredFields,
              deleteErrorsHeader: headerFieldsActions.deleteErrorsHeader
            })

            lineItemsActions.updateLines({
              data: {
                lines: deltaRes.detail?.lineItems
              },
              requiredFields,
              columns: columnsData.columns.map(x => x.name ?? '')
            })
          }
        },
        onError: error => {
          setCreateError({
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            isOpen: true,
            color: 'danger'
          })
        }
      }
    )
  }

  const onIgnore = () => {
    history.push(`/admin/invoiceAI/${invoiceId}/details`)
  }

  function leftRightFields() {
    const invoiceData = []
    const vendorInfo = []
    const invoiceAmount = []
    const addressInfo = []
    const start = []
    const taxInformation = []
    const customInformation = []

    // eslint-disable-next-line
    for (const [index, headerField] of headerFields.entries()) {
      let jsxElement
      const pos = HeaderFieldsStandard[headerField.field]?.pos ?? 0

      const rule = requiredFields.find(
        x => x.standardField === headerField.field && x.level === 'header'
      )

      const label = rule?.displayName ?? ''

      const dataType = rule?.validations?.find(x => x.type === 'dataType')

      switch (dataType?.value) {
        case 'companyCode': {
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                <SelectCompanyCode
                  control={control}
                  index={index}
                  label={label}
                  headerField={headerField}
                  headerData={headerData}
                  headerFieldsActions={headerFieldsActions}
                  appId={appId}
                  getValues={getValues}
                  setValueForm={setValueForm}
                  appConfig={appConfig}
                  requiredFields={requiredFields}
                  readOnly={readOnly}
                />
              </Grid>
            ),
            pos
          }
          break
        }
        case 'companyAddress': {
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                <AddressSearch
                  control={control}
                  index={index}
                  label={label}
                  headerField={headerField}
                  headerData={headerData}
                  headerFieldsActions={headerFieldsActions}
                  appId={appId}
                  getValues={getValues}
                  setValueForm={setValueForm}
                  requiredFields={requiredFields}
                  readOnly={readOnly}
                />
              </Grid>
            ),
            pos
          }
          break
        }
        case 'supplierLocation':
        case 'supplierRemitToAddress': {
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                <SupplierAddressSelect
                  control={control}
                  index={index}
                  label={label}
                  headerField={headerField}
                  headerData={headerData}
                  headerFieldsActions={headerFieldsActions}
                  appId={appId}
                  getValues={getValues}
                  setValueForm={setValueForm}
                  requiredFields={requiredFields}
                  readOnly={readOnly}
                />
              </Grid>
            ),
            pos
          }
          break
        }
        case 'supplier':
          if (headerField.field === HeaderFieldsStandard['supplier.id'].type) {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                  <SupplierSearchId
                    control={control}
                    index={index}
                    label={label}
                    headerField={headerField}
                    headerData={headerData}
                    headerFieldsActions={headerFieldsActions}
                    appId={appId}
                    getValues={getValues}
                    setValueForm={setValueForm}
                    requiredFields={requiredFields}
                    readOnly={readOnly}
                  />
                </Grid>
              ),
              pos
            }
            break
          }
          if (headerField.field === HeaderFieldsStandard['supplier.name'].type) {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                  <SupplierSearchName
                    control={control}
                    index={index}
                    label={label}
                    headerField={headerField}
                    headerData={headerData}
                    headerFieldsActions={headerFieldsActions}
                    appId={appId}
                    getValues={getValues}
                    setValueForm={setValueForm}
                    requiredFields={requiredFields}
                    readOnly={readOnly}
                  />
                </Grid>
              ),
              pos
            }
            break
          }

          break
        case 'date':
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                <DatePickerField
                  control={control}
                  index={index}
                  label={label}
                  headerField={headerField}
                  headerData={headerData}
                  headerFieldsActions={headerFieldsActions}
                  requiredFields={requiredFields}
                  getValues={getValues}
                  readOnly={readOnly}
                />
              </Grid>
            ),
            pos
          }

          break
        case 'boolean':
          if (headerField.field === HeaderFieldsStandard.nonPoInvoice.type) {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                  <SwitchField
                    control={control}
                    index={index}
                    label={label}
                    headerField={headerField}
                    headerData={headerData}
                    headerFieldsActions={headerFieldsActions}
                    onChange={value => {
                      trigger()
                      if (!value) {
                        const fieldToChangeValidation = [
                          HeaderFieldsStandard.poNumber.type,
                          HeaderFieldsStandard['supplier.id'].type
                        ]

                        headerFieldsActions.deleteErrorsHeader({
                          fields: fieldToChangeValidation
                        })
                      }
                    }}
                    readOnly={readOnly}
                    getValues={getValues}
                    requiredFields={requiredFields}
                  />
                </Grid>
              ),
              pos
            }
          } else {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                  <SwitchField
                    control={control}
                    index={index}
                    label={label}
                    headerField={headerField}
                    headerData={headerData}
                    headerFieldsActions={headerFieldsActions}
                    readOnly={readOnly}
                    getValues={getValues}
                    requiredFields={requiredFields}
                  />
                </Grid>
              ),
              pos
            }
          }

          break
        default: {
          if (headerField.field === HeaderFieldsStandard.poNumber.type) {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                  <PoSearch
                    setValueForm={setValueForm}
                    appId={appId}
                    setIsPoWarning={setIsPoWarning}
                    isPoWarning={isPoWarning}
                    control={control}
                    index={index}
                    label={label}
                    headerField={headerField}
                    headerData={headerData}
                    headerFieldsActions={headerFieldsActions}
                    setCreateError={setCreateError}
                    createError={createError}
                    setPoModal={setPoModal}
                    onChangePo={changePo}
                    requiredFields={requiredFields}
                    getValues={getValues}
                    readOnly={readOnly}
                  />
                </Grid>
              ),
              pos
            }
            break
          }
          if (headerField.field === HeaderFieldsStandard['currency.code'].type) {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                  <AutoCompleteCountries
                    control={control}
                    index={index}
                    label={label}
                    headerField={headerField}
                    headerData={headerData}
                    headerFieldsActions={headerFieldsActions}
                    requiredFields={requiredFields}
                    getValues={getValues}
                    readOnly={readOnly}
                  />
                </Grid>
              ),
              pos
            }
            break
          }
          if (headerField.field === HeaderFieldsStandard.documentType.type) {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                  <SelectInvoiceType
                    control={control}
                    index={index}
                    label={label}
                    headerField={headerField}
                    headerData={headerData}
                    headerFieldsActions={headerFieldsActions}
                    requiredFields={requiredFields}
                    getValues={getValues}
                    readOnly={readOnly || rule.isReadOnly}
                  />
                </Grid>
              ),
              pos
            }
            break
          }
          if (headerField.field === HeaderFieldsStandard.process.type) {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                  <SelectProcess
                    control={control}
                    index={index}
                    label={label}
                    headerField={headerField}
                    headerData={headerData}
                    headerFieldsActions={headerFieldsActions}
                    requiredFields={requiredFields}
                    getValues={getValues}
                    readOnly={readOnly || rule.isReadOnly}
                    appConfig={appConfig.data}
                    processList={processList}
                  />
                </Grid>
              ),
              pos
            }
            break
          }

          if (dataType?.value.startsWith('ocrFieldValues#')) {
            jsxElement = {
              jsx: (
                <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                  <AutoCompleteAsyncValuesHeader
                    control={control}
                    index={index}
                    label={label}
                    headerField={headerField}
                    headerData={headerData}
                    headerFieldsActions={headerFieldsActions}
                    requiredFields={requiredFields}
                    getValues={getValues}
                    readOnly={readOnly}
                  />
                </Grid>
              ),
              pos
            }
            break
          }
          jsxElement = {
            jsx: (
              <Grid item xs={12} sm={12} md={12} lg={12} key={headerField.id}>
                <HeaderField
                  control={control}
                  index={index}
                  label={label}
                  headerField={headerField}
                  headerData={headerData}
                  headerFieldsActions={headerFieldsActions}
                  requiredFields={requiredFields}
                  getValues={getValues}
                  readOnly={readOnly || rule.isReadOnly}
                />
              </Grid>
            ),
            pos
          }

          break
        }
      }

      if (!jsxElement) {
        continue
      }

      if (rule?.isCustomField) {
        customInformation.push(jsxElement)
        continue
      }

      switch (HeaderFieldsStandard[headerField.field]?.section) {
        case 'invoiceData':
          invoiceData.push(jsxElement)
          break
        case 'vendorInfo':
          vendorInfo.push(jsxElement)
          break
        case 'invoiceAmount':
          invoiceAmount.push(jsxElement)
          break
        case 'addressInfo':
          addressInfo.push(jsxElement)
          break
        case 'start':
          start.push(jsxElement)
          break
        case 'taxInfo':
          taxInformation.push(jsxElement)
          break
        default:
          break
      }
    }

    invoiceData.sort(sortElements)
    vendorInfo.sort(sortElements)
    invoiceAmount.sort(sortElements)
    addressInfo.sort(sortElements)
    start.sort(sortElements)
    taxInformation.sort(sortElements)
    customInformation.sort(sortElements)

    const invoiceDataJsx = invoiceData.map(jsxElement => jsxElement.jsx)
    const vendorInfoJsx = vendorInfo.map(jsxElement => jsxElement.jsx)
    const invoiceAmountJsx = invoiceAmount.map(jsxElement => jsxElement.jsx)
    const addressInfoJsx = addressInfo.map(jsxElement => jsxElement.jsx)
    const startJsx = start.map(jsxElement => jsxElement.jsx)
    const taxInfoJsx = taxInformation.map(jsxElement => jsxElement.jsx)
    const customInfoJsx = customInformation.map(jsxElement => jsxElement.jsx)

    const isLineSupplierShipAddressVisible = true
    const isLineBuyerShipAddressVisible = true

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={3}>
            {startJsx.length > 2 ? <Grid item xs={6} sm={6} md={6} lg={6}></Grid> : null}
            {startJsx.length !== 0 ? startJsx : null}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Key Invoice Data</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {invoiceDataJsx.length !== 0 ? invoiceDataJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Invoice Amount Information</b>
                  </h5>

                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {invoiceAmountJsx.length !== 0 ? invoiceAmountJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h5 className={classes.colorPrimary}>
                    <b>Vendor Information</b>
                  </h5>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {vendorInfoJsx.length !== 0 ? vendorInfoJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    style={{
                      display: 'flex'
                    }}
                  >
                    <h5 className={classes.colorPrimary}>
                      <b>Address Information</b>
                    </h5>
                    {isLineSupplierShipAddressVisible || isLineBuyerShipAddressVisible ? (
                      <></>
                    ) : null}
                  </Box>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent="center" spacing={2} alignItems="center">
                    {addressInfoJsx.length !== 0 ? addressInfoJsx : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {taxInfoJsx.length !== 0 ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h5 className={classes.colorPrimary}>
                      <b>Tax Information</b>
                    </h5>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justifyContent="center" spacing={2} alignItems="center">
                      {taxInfoJsx}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            </Grid>
          </Grid>
        ) : null}
        {customInfoJsx.length !== 0 ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h5 className={classes.colorPrimary}>
                      <b>Client Managed Fields</b>
                    </h5>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justifyContent="center" spacing={2} alignItems="center">
                      {customInfoJsx}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    )
  }

  function handleOnChangeInvoice(invoiceId) {
    onChangeInvoice(invoiceId)
    setIsPoWarning(true)
  }

  const accountSegmentsRow =
    selectedLine.length > 0 && lineItems.lineItemsAccountSegments
      ? lineItems.lineItemsAccountSegments[selectedLine[0]]
      : null

  const lineItemSelected = lineItems.lines.find(x => x.id === selectedLine[0])

  return (
    <Grid container direction="column">
      {!readOnly ? (
        <Grid container direction="row" className={classesContainer.root}>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Button
              style={{ maxWidth: '36px', minWidth: '36px' }}
              classes={{ startIcon: classesButtons.startICon }}
              className={classes.btnColorPrimary}
              variant="outlined"
              color="primary"
              disabled={!Boolean(invoicesExceptions?.previous)}
              onClick={() => handleOnChangeInvoice(invoicesExceptions.previous)}
              startIcon={<ArrowBackIosOutlinedIcon style={{ fontSize: 20 }} />}
            />
          </Grid>

          <Grid item xs={10} sm={10} md={10} lg={10}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                className={classes.btnColorPrimary}
                onClick={() => onSave()}
                disabled={isLoadingUpdate}
                color="primary"
                variant="outlined"
                style={{ marginRight: '5px' }}
              >
                {isLoadingUpdate ? 'Saving...' : 'Save'}
              </Button>
              <Button
                onClick={() => setSimpleModal(old => ({ ...old, isOpenRejectModal: true }))}
                color="primary"
                type="button"
                disabled={updateStatusInvoice.isLoading}
                variant="outlined"
                className={classes.btnColorPrimary}
                style={{ marginRight: '5px' }}
              >
                {updateStatusInvoice.isLoading ? 'Rejecting...' : 'Reject'}
              </Button>
              <Button
                onClick={() => setSimpleModal(old => ({ ...old, isOpenIgnoreModal: true }))}
                color="primary"
                type="button"
                variant="outlined"
                className={classes.btnColorPrimary}
                style={{ marginRight: '5px' }}
              >
                {'Ignore'}
              </Button>
              <Button
                disabled={validationInvoice.isLoading}
                type="button"
                className={classes.btnColorPrimary}
                variant="outlined"
                onClick={() => onSubmit()}
              >
                {validationInvoice.isLoading ? 'Submitting...' : 'Submit'}
              </Button>

              {isCustomerMappingsEnabled ? (
                <ButtonGroup variant="outlined" style={{ marginLeft: '5px' }}>
                  <Button
                    variant="outlined"
                    size="medium"
                    color="primary"
                    className={classes.btnColorPrimary}
                    onClick={() => updateInvoiceOcrDataCustomRules()}
                    disabled={readOnly}
                  >
                    {applyBusinessRule.isLoading ? 'Applying Rules...' : 'Trigger Rules'}
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    className={classes.btnColorPrimary}
                    onClick={() => setSimpleModal(old => ({ ...old, isOpenRulesModal: true }))}
                    disabled={readOnly}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                style={{ maxWidth: '36px', minWidth: '36px' }}
                classes={{ startIcon: classesButtons.startICon }}
                className={classes.btnColorPrimary}
                color="primary"
                variant="outlined"
                disabled={!Boolean(invoicesExceptions?.next)}
                onClick={() => handleOnChangeInvoice(invoicesExceptions.next)}
                startIcon={<ArrowForwardIosOutlinedIcon style={{ fontSize: 20 }} />}
              />
            </div>
          </Grid>
        </Grid>
      ) : null}

      <Grid container style={{ marginTop: '10px' }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <AppBar position={'static'} color="default">
            <>
              <div className={classesBadges.root}>
                <IconButton
                  onClick={() =>
                    setShowModal({
                      isOpen: true,
                      info: validationInfo.errorInfo,
                      type: 'error'
                    })
                  }
                  size="small"
                  disabled={validationCount?.errorCount <= 0 || readOnly}
                >
                  <Badge
                    badgeContent={validationCount?.errorCount}
                    style={{ color: 'red' }}
                    classes={{
                      badge: classesBadges.errorBadge
                    }}
                  >
                    <ErrorIcon
                      fontSize="small"
                      className={
                        validationCount?.errorCount > 0
                          ? classes.primaryColor
                          : classes.disabledColor
                      }
                    />
                  </Badge>
                </IconButton>

                <IconButton
                  onClick={() =>
                    setShowModal({
                      isOpen: true,
                      info: validationInfo.warningInfo,
                      type: 'warning'
                    })
                  }
                  size="small"
                  disabled={validationCount?.warningCount <= 0 || readOnly}
                >
                  <Badge
                    badgeContent={validationCount?.warningCount}
                    style={{ color: '#f3bc07' }}
                    classes={{
                      badge: classesBadges.warningBadge
                    }}
                  >
                    <WarningIcon
                      fontSize="small"
                      className={
                        validationCount?.warningCount > 0
                          ? classes.primaryColor
                          : classes.disabledColor
                      }
                    />
                  </Badge>
                </IconButton>

                <IconButton
                  onClick={() => setSimpleModal(old => ({ ...old, isOpenCommentsModal: true }))}
                  size="small"
                >
                  <Badge
                    badgeContent={commentsInformation.data?.length}
                    classes={{ badge: classesBadges.badge }}
                  >
                    <SpeakerNotesIcon fontSize="small" style={{ color: '#081c3e' }} />
                  </Badge>
                </IconButton>
              </div>
            </>
            <Tabs
              value={tab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Header Fields" {...a11yProps(0)} />
              <Tab label="Tax & Other Charges" {...a11yProps(1)} />
              <Tab label="Line item fields" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={tab}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={tab} index={0} dir={theme.direction}>
              <Grid container justifyContent="center" style={{ width: '100%' }} spacing={2}>
                {applyPOMappings.isLoading ? (
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '1300',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#f8f8f8ad'
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </div>
                ) : null}
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                  <h3 style={{ color: '#081c3e' }}>Required Fields</h3>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {leftRightFields()}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1} dir={theme.direction}>
              <Grid container justifyContent="center" spacing={2} style={{ paddingTop: '15px' }}>
                <Grid item xs={7} sm={7} md={7} lg={7}>
                  <Tooltip title={'Previous'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      disabled={additionalCharges.pagination.skip === 0}
                      onClick={updateSkipAdditionalCharges('previous')}
                    >
                      <NavigateBeforeOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={'Next'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      onClick={updateSkipAdditionalCharges('next')}
                      disabled={
                        additionalCharges.pagination.currentPage ===
                        additionalCharges.pagination.numberOfPages
                      }
                    >
                      <NavigateNextOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  {readOnly ? null : (
                    <>
                      <Tooltip title={'Add Row'}>
                        <Fab
                          size="small"
                          style={{
                            margin: '0 5px 0 5px',
                            backgroundColor: '#081c3e'
                          }}
                          onClick={onAddNewRowAdditional}
                        >
                          <AddIcon fontSize="small" style={{ color: 'eeeeee' }} />
                        </Fab>
                      </Tooltip>
                      <Tooltip title={'Delete Rows'}>
                        <Fab
                          size="small"
                          style={{
                            margin: '0 5px 0 5px',
                            backgroundColor: '#081c3e'
                          }}
                          onClick={() => handleDeleteBatchLineItems('charges')}
                        >
                          <DeleteForeverIcon fontSize="small" style={{ color: 'eeeeee' }} />
                        </Fab>
                      </Tooltip>
                      <Tooltip title={'Combine Rows'}>
                        <Fab
                          size="small"
                          style={{
                            margin: '0 5px 0 5px',
                            backgroundColor: '#081c3e'
                          }}
                          onClick={handleMergeRows}
                        >
                          <MergeTypeIcon fontSize="small" style={{ color: 'eeeeee' }} />
                        </Fab>
                      </Tooltip>
                    </>
                  )}
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      onClick={() => updateTotals()}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className={classes.btnColorPrimary}
                      disabled={readOnly}
                    >
                      Update Totals
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ReactDataGrid
                    idProperty="id"
                    selected={additionalCharges.selectedLine.selected}
                    unselected={additionalCharges.selectedLine.unselected}
                    checkboxColumn={true}
                    onSelectionChange={onSelectionChangeAdditionalCharges}
                    onReady={setGridAdditionalRef}
                    columns={columnsCharges.columns}
                    dataSource={additionalCharges.lines}
                    style={gridChargesStyle}
                    showColumnMenuTool={false}
                    onEditComplete={onEditCompleteAdditionalCharges}
                    renderRowContextMenu={readOnly ? null : renderRowContextMenuAdditionalCharges}
                    pagination
                    sortable={false}
                    cellStyle={{}}
                    emptyText="There are no charges to show"
                    cellSelection={additionalCharges.selectedLine.cell}
                    onCellSelectionChange={cellSelected =>
                      setSelectedAdditionalCharges({ cellSelected })
                    }
                    enableKeyboardNavigation={false}
                    defaultLimit={additionalCharges.pagination.dataPerPage}
                    activeIndexThrottle={300}
                    scrollProps={scrollProps}
                    skip={additionalCharges.pagination.skip}
                    renderPaginationToolbar={() => null}
                    onCellClick={onCellClickAdditionalCharges}
                    checkboxOnlyRowSelect={true}
                    minRowHeight={50}
                    rowHeight={null}
                    editable={!readOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title={'Previous'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      disabled={taxes.pagination.skip === 0}
                      onClick={updateSkipTaxes('previous')}
                    >
                      <NavigateBeforeOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={'Next'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      onClick={updateSkipTaxes('next')}
                      disabled={taxes.pagination.currentPage === taxes.pagination.numberOfPages}
                    >
                      <NavigateNextOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  {readOnly ? null : (
                    <>
                      <Tooltip title={'Add Row'}>
                        <Fab
                          size="small"
                          style={{
                            margin: '0 5px 0 5px',
                            backgroundColor: '#081c3e'
                          }}
                          onClick={onAddNewRowTaxes}
                        >
                          <AddIcon fontSize="small" style={{ color: 'eeeeee' }} />
                        </Fab>
                      </Tooltip>
                      <Tooltip title={'Delete Rows'}>
                        <Fab
                          size="small"
                          style={{
                            margin: '0 5px 0 5px',
                            backgroundColor: '#081c3e'
                          }}
                          onClick={() => handleDeleteBatchLineItems('taxes')}
                        >
                          <DeleteForeverIcon fontSize="small" style={{ color: 'eeeeee' }} />
                        </Fab>
                      </Tooltip>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ReactDataGrid
                    idProperty="id"
                    selected={taxes.selectedLine.selected}
                    unselected={taxes.selectedLine.unselected}
                    checkboxColumn={true}
                    onSelectionChange={onSelectionChangeTaxes}
                    onReady={setGridTaxesRef}
                    columns={columnsTaxes.columns}
                    dataSource={taxes.lines}
                    style={gridChargesStyle}
                    showColumnMenuTool={false}
                    onEditComplete={onEditCompleteTaxes}
                    renderRowContextMenu={readOnly ? null : renderRowContextMenuTaxes}
                    pagination
                    sortable={false}
                    cellStyle={{}}
                    emptyText="There are no taxes to show"
                    cellSelection={taxes.selectedLine.cell}
                    onCellSelectionChange={cellSelected => setSelectedTaxes({ cellSelected })}
                    enableKeyboardNavigation={false}
                    defaultLimit={taxes.pagination.dataPerPage}
                    activeIndexThrottle={300}
                    scrollProps={scrollProps}
                    skip={taxes.pagination.skip}
                    renderPaginationToolbar={() => null}
                    onCellClick={onCellClickTaxes}
                    checkboxOnlyRowSelect={true}
                    minRowHeight={50}
                    rowHeight={null}
                    editable={!readOnly}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={2} dir={theme.direction}>
              <Grid container justifyContent="center" spacing={2} style={{ paddingTop: '15px' }}>
                <Grid item xs={7} sm={7} md={7} lg={7}>
                  <Tooltip title={'Previous'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      disabled={lineItems.pagination.skip === 0}
                      onClick={updateSkip('previous')}
                    >
                      <NavigateBeforeOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  <Tooltip title={'Next'}>
                    <Fab
                      size="small"
                      style={{
                        margin: '0 5px 0 5px',
                        backgroundColor: '#081c3e'
                      }}
                      onClick={updateSkip('next')}
                      disabled={
                        lineItems.pagination.currentPage === lineItems.pagination.numberOfPages
                      }
                    >
                      <NavigateNextOutlinedIcon fontSize="small" style={{ color: 'eeeeee' }} />
                    </Fab>
                  </Tooltip>
                  {readOnly ? null : (
                    <>
                      <Tooltip title={'Add Row'}>
                        <Fab
                          size="small"
                          style={{
                            margin: '0 5px 0 5px',
                            backgroundColor: '#081c3e'
                          }}
                          onClick={onAddNewRow}
                        >
                          <AddIcon fontSize="small" style={{ color: 'eeeeee' }} />
                        </Fab>
                      </Tooltip>
                      <Tooltip title={'Delete Rows'}>
                        <Fab
                          size="small"
                          style={{
                            margin: '0 5px 0 5px',
                            backgroundColor: '#081c3e'
                          }}
                          onClick={() => handleDeleteBatchLineItems('lines')}
                        >
                          <DeleteForeverIcon fontSize="small" style={{ color: 'eeeeee' }} />
                        </Fab>
                      </Tooltip>
                      <Tooltip title={'Combine Rows'}>
                        <Fab
                          size="small"
                          style={{
                            margin: '0 5px 0 5px',
                            backgroundColor: '#081c3e'
                          }}
                          onClick={handleMergeRows}
                        >
                          <MergeTypeIcon fontSize="small" style={{ color: 'eeeeee' }} />
                        </Fab>
                      </Tooltip>
                      <Tooltip title={'Update Lines with Po Information'}>
                        <Fab
                          size="small"
                          style={{
                            margin: '0 5px 0 5px',
                            backgroundColor: '#081c3e'
                          }}
                          onClick={() => setPoModal(old => ({ ...old, update: true }))}
                        >
                          <DoubleArrowIcon width="25px" height="25px" />
                        </Fab>
                      </Tooltip>
                    </>
                  )}
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      onClick={() => updateTotals()}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className={classes.btnColorPrimary}
                      disabled={readOnly}
                    >
                      Update Totals
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ReactDataGrid
                    idProperty="id"
                    selected={lineItems.selectedLine.selected}
                    unselected={lineItems.selectedLine.unselected}
                    checkboxColumn={true}
                    onSelectionChange={onSelectionChange}
                    onReady={setGridRef}
                    columns={columnsData.columns}
                    dataSource={lineItems.lines}
                    style={gridStyle}
                    showColumnMenuTool={false}
                    onEditComplete={onEditComplete}
                    renderRowContextMenu={readOnly ? null : renderRowContextMenu}
                    loading={applyPOMappings.isLoading}
                    sortable={false}
                    pagination
                    cellStyle={{}}
                    emptyText="There are no line items to show"
                    cellSelection={lineItems.selectedLine.cell}
                    onCellSelectionChange={cellSelected => setSelectedLines({ cellSelected })}
                    enableKeyboardNavigation={false}
                    onCellClick={onCellClick}
                    activeIndexThrottle={300}
                    scrollProps={scrollProps}
                    checkboxOnlyRowSelect={true}
                    defaultLimit={lineItems.pagination.dataPerPage}
                    skip={lineItems.pagination.skip}
                    renderPaginationToolbar={() => null}
                    minRowHeight={50}
                    rowHeight={null}
                    editable={!readOnly}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>
      <Snackbar
        place="bl"
        color={createError.color ? createError.color : 'danger'}
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() => setCreateError({ isOpen: false, message: '', color: 'danger' })}
        close
      />
      <Snackbar
        place="bl"
        color="success"
        icon={AddAlert}
        message={submitSuccess.message}
        open={submitSuccess.isOpen}
        closeNotification={() => setSubmitSuccess({ isOpen: false, message: '' })}
        close
      />
      <DeleteConfirmationModal
        showModal={deleteModal.isOpen}
        onSuccess={() => handleDelete()}
        onClose={onClose}
        title={'Are you sure you want to delete the rows selected?'}
      />
      <ModalComments
        showModal={simpleModal.isOpenCommentsModal}
        onClose={() => setSimpleModal(old => ({ ...old, isOpenCommentsModal: false }))}
        commentsInformation={commentsInformation}
        appId={appId}
        invoiceId={invoiceId}
      />
      <CombineModal
        showModal={simpleModal.isOpenCombineModal}
        onClose={() => setSimpleModal(old => ({ ...old, isOpenCombineModal: false }))}
        onSubmit={onSuccessMergeRows}
        selectedRows={{}}
      />
      <UpdateStatusDialog
        open={simpleModal.isOpenRejectModal}
        onClose={() => setSimpleModal(old => ({ ...old, isOpenRejectModal: false }))}
        changeInvoiceStatus={changeInvoiceStatus}
        status={'reject'}
        label={'Reject'}
      />
      <ValidationsModal
        showModal={showModal.isOpen}
        type={showModal.type}
        modalInfo={showModal.info ?? []}
        onClose={() =>
          setShowModal({
            isOpen: false
          })
        }
      />
      <InfoModal
        showModal={submitModal.isOpen}
        onSubmit={onSubmitModal}
        onClose={() => setSubmitModal({ isOpen: false, data: null })}
        warningMessage={submitModal.messageBody}
        title="Invoice Submission"
        textSuccessBtn={'Submit'}
        successBtn={submitModal.successBtn}
      />
      <PoModal
        showModal={poModal.update}
        onClose={() => setPoModal(old => ({ ...old, update: false }))}
        onSubmit={applyPoMappings}
        control={control}
        accountSegmentsEnabled={accountSegmentsEnabled}
        isDetailsEnabled={true}
        isHeaderSection={tab === 0}
        requiredFields={requiredFields}
        appId={appId}
      />
      <PoModalDetails
        showModal={poModal.details}
        onClose={() => setPoModal(old => ({ ...old, details: false }))}
        control={control}
        appConfig={appConfig}
        appId={appId}
      />
      {selectedLine[0] ? (
        <TaxLineModal
          key={`${selectedLine[0]}-${simpleModal.taxLine}`}
          showModal={simpleModal.taxLine}
          onClose={() => setSimpleModal(old => ({ ...old, taxLine: false }))}
          defaultValues={lineItems.taxAndCharges?.[selectedLine[0]]?.taxes ?? []}
          line={lineItemSelected}
          handleOnSuccess={onSuccessTaxesLine}
          process={invoiceData.process}
          isReadOnly={readOnly}
        />
      ) : null}
      {accountSegmentsEnabled ? (
        <ModalAccountType
          isOpen={simpleModal.isOpenAccountTypeModal}
          onClose={() => setSimpleModal(old => ({ ...old, isOpenAccountTypeModal: false }))}
          defaultDataSource={accountSegmentsRow?.accountSegments ?? []}
          onComplete={handleOnCompleteAccountSegments}
          isAccountValidationEnabled={isAccountValidationEnabled}
          appId={appId}
          setCreateError={setCreateError}
          isReadOnly={readOnly}
          serviceType={appConfig.data.service}
        />
      ) : null}
      <RuleSelectModal
        label="Apply"
        title="Rules"
        open={simpleModal.isOpenRulesModal}
        onClose={() => setSimpleModal(old => ({ ...old, isOpenRulesModal: false }))}
        onApply={rules => updateInvoiceOcrDataCustomRules(rules)}
        appId={appId}
      />
      <IgnoreModal
        showModal={simpleModal.isOpenIgnoreModal}
        onClose={() => setSimpleModal(old => ({ ...old, isOpenIgnoreModal: false }))}
        onSubmit={onIgnore}
        documentId={invoiceId}
      />
      <AdditionalDetails
        isOpen={simpleModal.isOpenAddressModal}
        onClose={() => setSimpleModal(old => ({ ...old, isOpenAddressModal: false }))}
        lineItemSelected={lineItemSelected}
        readOnly={readOnly}
        onEditComplete={onEditComplete}
        requiredFields={requiredFields}
        process={process}
        lines={lineItems}
        lineItemsActions={lineItemsActions}
        appId={appId}
      />
    </Grid>
  )
})
